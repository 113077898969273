import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import image404 from "../images/404.svg"
import BackButton from '../components/BackButton';

const NotFoundPage = () => (
  <Layout>
    
    <section className="features">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center main-404-wrapper">
              <img src={image404} class="img-fluid mx-auto d-block" alt='404 page' />
              <h1>Sorry! We couldn’t find this page</h1>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 text-center main-404-btns-wrapper">
                <Link to='/' className="btn btn-outline">Open homepage</Link>
                <BackButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
